import React, { FC } from "react";
export { Head } from "src/utils/head";

import { Layout } from "components/template/layout";

import { HeroSection } from "./_heroSection";
import { DescriptionSection } from "./_descriptionSection";
import { JobsSection } from "./_jobsSection";

const CareersPage: FC = () => (
  <Layout>
    <HeroSection />
    <DescriptionSection />
    <JobsSection />
  </Layout>
);

export default CareersPage;
