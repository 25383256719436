import React, { FC } from "react";
import styled from "styled-components";

type Props = {
  mb?: number;
  mt?: number;
  ml?: number;
};

const SubtitleStyled = styled.h2<Props>`
  margin-bottom: ${({ mb }) => mb}px;
  margin-top: ${({ mt }) => mt}px;
  margin-left: ${({ ml }) => ml}px;
  font-family: "Post Grotesk Bold";
  font-size: 24px;
`;

export const Subtitle: FC<Props> = ({ children, mb, mt, ml }) => {
  return (
    <SubtitleStyled mb={mb} mt={mt} ml={ml}>
      {children}
    </SubtitleStyled>
  );
};
