import React, { FC, useRef, useState } from "react";
import styled from "styled-components";

import { Text } from "components/atoms/text";
import chevronDown from "assets/icons/chevron-down.svg";
import chevronUp from "assets/icons/chevron-up.svg";
import { CategoryAndJobsType } from "src/utils/careers";

type CollapsibleProps = {
  category: string;
  jobs: CategoryAndJobsType["jobs"];
};

const CollapsibleHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 24px;
  cursor: pointer;
`;

const CollapsibleWrapper = styled.div`
  margin-bottom: 80px;
  border-bottom: solid 1px ${({ theme }) => theme.colors.gray["300"]};
  width: 100%;
`;

const CollapsibleContainer = styled.div<{ height: number }>`
  height: ${({ height }) => height}px;
  transition: 0.2s ease-in-out;
  overflow: hidden;
`;

const CollapsibleChildren = styled.div`
  margin-left: 2.5rem;
  display: flex;
  flex-direction: column;
`;

const StyledLink = styled.a`
  text-decoration: underline;
  font-family: "Post Grotesk";
  margin-bottom: 24px;
  &:hover {
    background: ${({ theme }) => theme.colors.black};
    color: ${({ theme }) => theme.colors.white};
  }
`;

export const Collapsible: FC<CollapsibleProps> = ({ category, jobs }) => {
  const CollapsibleChildrenRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(0);
  const [isOpen, setIsopen] = useState(false);

  const handleCollapse = () => {
    if (height === 0 && CollapsibleChildrenRef.current) {
      setHeight(CollapsibleChildrenRef.current.clientHeight);
      setIsopen(true);
    } else {
      setHeight(0);
      setIsopen(false);
    }
  };
  return (
    <CollapsibleWrapper>
      <div>
        <CollapsibleHeader onClick={handleCollapse}>
          <Text fontFamily="Post Grotesk Bold">{category}</Text>
          <div>
            <img src={isOpen ? chevronUp : chevronDown} />
          </div>
        </CollapsibleHeader>
      </div>

      <CollapsibleContainer height={height}>
        <CollapsibleChildren ref={CollapsibleChildrenRef}>
          {jobs?.map((jobs) => (
            <StyledLink href={jobs.link} target="_blank">
              {jobs.title}
            </StyledLink>
          ))}
        </CollapsibleChildren>
      </CollapsibleContainer>
    </CollapsibleWrapper>
  );
};
