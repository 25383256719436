import React, { FC } from "react";
import styled from "styled-components";

import Background from "assets/images/hub/career_background.png";

const HeroSectionContainer = styled.section`
  margin-top: 80px;
  margin-bottom: 80px;

  @media (max-width: ${({ theme }) => theme.device.landscapeTablet}px) {
    margin-bottom: 40px;
  }
`;

const Image = styled.img`
  width: 100%;
`;

export const HeroSection: FC = () => (
  <HeroSectionContainer>
    <Image src={Background} />
  </HeroSectionContainer>
);
