import styled from "styled-components";

export const HorizontalBar = styled.hr`
  background: ${({ theme }) => theme.colors.gray["300"]};
  height: 1px;
  border-width: 0;

  @media (max-width: ${({ theme }) => theme.device.mobile}px) {
    margin-bottom: 40px;
  }
`;
