import React, { FC } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { Text } from "components/atoms/text";
import { Subtitle } from "components/atoms/subtitle";
import { Button } from "components/atoms/button";
import { HorizontalBar } from "components/atoms/horizontalBar";

const DescriptionSectionContainer = styled.section`
  margin-bottom: 80px;
  padding: 0 100px;

  @media (max-width: ${({ theme }) => theme.device.landscapeTablet}px) {
    padding: 0 60px;
  }

  @media (max-width: ${({ theme }) => theme.device.mobile}px) {
    padding: 0 40px;
  }
`;

const DescriptionSectionWrapper = styled.div`
  display: flex;
  grid-gap: 200px;
  justify-content: space-between;
  margin-bottom: 80px;
  margin-left: 60px;
  @media (max-width: ${({ theme }) => theme.device.landscapeTablet}px) {
    grid-gap: 100px;
  }
  @media (max-width: ${({ theme }) => theme.device.portraitTablet}px) {
    margin-left: 0;
    flex-direction: column;
    grid-gap: 32px;
    margin-bottom: 64px;
    margin-left: 0;
  }
`;

const DescriptionSectionWrappedItem = styled.div`
  flex: 2;
  align-items: center;
`;

const ButtonSectionWrappedItem = styled.div`
  flex: 1;
  display: flex;
  padding-bottom: 40px;
  align-items: flex-end;
`;

export const DescriptionSection: FC = () => {
  const { t } = useTranslation();

  return (
    <DescriptionSectionContainer>
      <DescriptionSectionWrapper>
        <DescriptionSectionWrappedItem>
          <Subtitle mb={40}>{t("career.description.subtitle")}</Subtitle>
          <Text>{t("career.description.text")}</Text>
        </DescriptionSectionWrappedItem>
        <ButtonSectionWrappedItem>
          <Button
            fullWidth
            href="https://www.linkedin.com/company/societhy/jobs/"
          >
            {t("career.description.positionsCta")}
          </Button>
        </ButtonSectionWrappedItem>
      </DescriptionSectionWrapper>
      <HorizontalBar />
    </DescriptionSectionContainer>
  );
};
