export type CategoryAndJobsType = {
  category: string;
  jobs: {
    title: string;
    link: string;
  }[];
};

export const CATEGORIES_AND_JOBS = [
  {
    category: "TECH",
    jobs: [
      {
        title: "UNREAL ENGINE DEVELOPER",
        link: "https://societhy.notion.site/Unreal-Engine-Developer-4266a15ecfeb410ea89552b6033c065f",
      },
    ],
  },
  {
    category: "DESIGN",
    jobs: [
      {
        title: "3D FASHION DESIGNER",
        link: "https://societhy.notion.site/3D-Fashion-designer-4a25637ee590464a8aca4d527938309c",
      },
    ],
  },
  ,
  {
    category: "COM",
    jobs: [
      {
        title: "BUSINESS DEV",
        link: "https://societhy.notion.site/Business-Developer-a489d06c422640c29a139f9f883af8c5",
      },
    ],
  },
  {
    category: "OPS",
    jobs: [
      {
        title: "RIGHT ARM CEO",
        link: "https://www.notion.so/societhy/Right-Arm-CEO-c9e83fd8455c4bf7859d5f446946addf",
      },
    ],
  },
];
