import React, { FC, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { Text } from "components/atoms/text";
import { Title } from "components/atoms/title";
import { Collapsible } from "components/molecules/collapsible";
import { CATEGORIES_AND_JOBS } from "src/utils/careers";

const JobsSectionContainer = styled.section`
  margin-bottom: 120px;
  padding: 0 100px;
  margin-left: 60px;

  @media (max-width: ${({ theme }) => theme.device.landscapeTablet}px) {
    margin-left: 0;
    padding: 0 60px;
  }

  @media (max-width: ${({ theme }) => theme.device.mobile}px) {
    padding: 0 40px;
  }
`;

const JobsSectionWrapper = styled.div`
  display: flex;
  grid-gap: 120px;
  justify-content: space-between;
  margin-bottom: 80px;
  align-items: flex-start;
  @media (max-width: ${({ theme }) => theme.device.desktop}px) {
    flex-direction: column;
  }

  @media (max-width: ${({ theme }) => theme.device.portraitTablet}px) {
    grid-gap: 0;
    padding-left: 0;
  }
`;

const JobsSectionWrappedItem = styled.div`
  margin-bottom: 100px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const JobsSection: FC = () => {
  const { t } = useTranslation();

  return (
    <JobsSectionContainer>
      <Title type="h3" mb={40} fontSize="120">
        {t("career.jobs.title")}
      </Title>
      <JobsSectionWrapper>
        <JobsSectionWrappedItem>
          <Text>{t("career.jobs.description")}</Text>
        </JobsSectionWrappedItem>

        <JobsSectionWrappedItem>
          {CATEGORIES_AND_JOBS.map((categoryAndJobs, i) => (
            <Collapsible
              key={i}
              category={categoryAndJobs.category}
              jobs={categoryAndJobs.jobs}
            />
          ))}
        </JobsSectionWrappedItem>
      </JobsSectionWrapper>
    </JobsSectionContainer>
  );
};
